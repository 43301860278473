@import url("https://fonts.googleapis.com/css?family=Roboto+Condensed&display=swap");
@import url('https://fonts.googleapis.com/css?family=Nunito&display=swap');
@import url('https://fonts.googleapis.com/css?family=Space+Mono&display=swap');
@import url('https://fonts.googleapis.com/css?family=Outfit:100,200,300,regular,500,600,700,800,900');

html { font-family: "Outfit", sans-serif; }

a { text-decoration: none; }

body {
  color: #fff;
  font-family: "Nunito", sans-serif;
  background-image: url("fondo.png");
  background-size: cover ;
  background-repeat: no-repeat;
  background-color: black;
  margin: 0;
}

.App {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: center;
}
.logo_container {
  width: 100%;
  margin-top: 5%;
  margin-left: 10%;
  text-align: left;
}
.logoimgage {
  margin-left: -20px;
  width: 250px;
}
.container {
  width: 100%;
  margin: 10% auto;
  text-align: center;
}

.status{
  display: inherit;
  -webkit-box-align: center;
  align-items: center;
  color: white;
  text-align: center;
  line-height: 1;
  white-space: nowrap;
  -webkit-font-smoothing: antialiased;
  font-weight: 600;
  font-size: 1rem;
}

a{
  font-family: "Nunito", sans-serif;
}
a.status:link {color:hsl(178, 100%, 50%)}
a.status:visited {color:hsl(178, 100%, 50%)}
a.status:hover {color:white}


.metamask-button{
  color: white;
  background: hsl(215, 32%, 27%);
  box-shadow: rgb(0 0 0 / 10%) 0px 2px 4px;
  font-family: inherit;
  position: relative;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
  line-height: 1;
  overflow: hidden;
  white-space: nowrap;
  -webkit-font-smoothing: antialiased;
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  transition: all 0.15s ease 0s;
  font-weight: 600;
  font-size: 1rem;
  min-width: 48px;
  box-sizing: border-box;
  margin: 0px 0px 16px;
  padding: 10px 32px;
  height: 48px;
  border: 0px;
  border-radius: 4px;
  letter-spacing: normal;
}

.metamask-span{
  display: inherit;
  -webkit-box-align: center;
  align-items: center;
  color: white;
  text-align: center;
  line-height: 1;
  white-space: nowrap;
  -webkit-font-smoothing: antialiased;
  font-weight: 600;
  font-size: 1rem;
}

div.gallery {
  margin: 5px;
  border: 1px solid #ccc;
  float: left;
  width: 180px;
}
  
div.gallery:hover {
  border: 1px solid #777;
}

div.gallery img {
  width: 100%;
  height: auto;
}

div.desc {
  font-family: "Nunito";
  padding: 10px;
  font-size: 10px;
  text-align: center;
}

h1 {
  font-size: 38px;
  font-family: 'Nunito';
  text-transform: uppercase;
  line-height: 1;
}

h2 {
  font-family: 'Space mono';
  line-height: 1;
}

/*-----------------------------------*\
  #VARIABLES
\*-----------------------------------*/

:root {
  --oxford-blue:       hsl(217, 54%, 11%);
  --light-oxford-blue: hsl(216, 50%, 16%);
  --indigo-dye:        hsl(215, 32%, 27%);
  --blue-yonder:       hsl(216, 30%, 55%);
  --aqua:              hsl(178, 100%, 50%);
  --white:             hsl(0, 0%, 100%);
}

.card {
  background: var(--light-oxford-blue);
  max-width: 320px;
  padding: 15px;
  display:inline-table;
  border-radius: 15px;
  font-family: "Outfit", sans-serif;
  text-align:     left;
  box-shadow: 0px 20px 25px 15px rgba(0, 0, 0, 0.05),
              0px 40px 30px 15px rgba(0, 0, 0, 0.1);
}

  .card__product-img {
    position: relative;
    border-radius: 8px;
    overflow: hidden;
    cursor: pointer;
  }

    .card__product-img::after {
      --aqua: hsla(178, 100%, 50%, 0.5);

      content: url(icon-view.svg);
      background: var(--aqua);
      position: absolute;
      inset: 0;
      display:         flex;
      justify-content: center;
      align-items:     center;
      opacity: 0;
      transition: all 0.25s ease;
    }

  .card__product-img:hover::after { opacity: 1; }

    .card__product-img img {
      width: 100%;
      display: block;
    }


  .card__body {
    padding: 5px 0;
    border-bottom: 1px solid var(--indigo-dye);
    margin-bottom: 15px;
  }

    .card__title {
      color: var(--white);
      font-size: 22px;
      font-weight: 500;
      margin-bottom: 15px;
      align-items: left;
      font-family: "Outfit", sans-serif;
    }

    .card__title:hover { color: var(--aqua); }

    .card__text {
      color: var(--blue-yonder);
      font-size: 18px;
      line-height: 1.5;
      margin-bottom: 23px;
    }

    .card__body .wrapper {
      display:         flex;
      justify-content: space-between;
      align-items:     center;
    }

    .card__price,
    .card__countdown {
      display:     flex;
      align-items: center;
      font-weight: 500;
    }

    .card__price { color: var(--aqua); }

    .card__icon { margin-right: 6px; width: 20px }

    .card__countdown { color: var(--blue-yonder); font-size: 12px; }


  .card__footer {
    display:     flex;
    align-items: center;
    margin-bottom: 10px;
  }

    .card__author-img {
      width:  34px;
      height: 34px;
      border: 2px solid var(--white);
      border-radius: 50px;
      margin-right: 15px;
    }

    .card__author-name {
      color: var(--blue-yonder);
      font-weight: 500;
    }

    .card__author-name a {
      color: var(--white);
      font-weight: 400;
    }

    .card__author-name a:hover { color: var(--aqua); }
